
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { useStore } from 'vuex'
import * as Yup from 'yup'
import { Actions } from '@/store/enums/StoreEnums'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'

export default defineComponent({
  name: 'password-reset',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup () {
    const store = useStore()

    const submitButton = ref<HTMLButtonElement | null>(null)

    // Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label('Email')
    })

    // Form submit function
    const onSubmitActivateAccount = async (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute('data-kt-indicator', 'on')

      await store.dispatch(Actions.GET_CSRF_COOKIE)
      await store.dispatch(Actions.API_ACCOUNT_REQUEST_ACTIVATE, values)

      const [errorName] = Object.keys(store.getters.getErrors)
      const error = store.getters.getErrors[errorName]

      if (!error) {
        Swal.fire({
          text: 'Nice ! Check your inbox for the validation link.',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-primary'
          }
        })
      } else {
        Swal.fire({
          text: error[0],
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again!',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      submitButton.value?.removeAttribute('data-kt-indicator')
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
    }

    return {
      onSubmitActivateAccount,
      forgotPassword,
      submitButton
    }
  }
})
